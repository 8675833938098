import React, { useContext ,useRef, useEffect} from 'react'
import { AuthContext } from '../context/AuthContext';
import { ChatContext } from '../context/ChatContext';

const Message = ({message}) => {
  const {currentUser} = useContext(AuthContext);
  const {data} = useContext(ChatContext);
  const ref = useRef();


  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  return (
    <div
    ref={ref}
    className={`message ${message.senderId === currentUser.uid && "owner"}`}
  >
      <div className='messageInfo'>
        <img src= {message.sendId === currentUser.uid 
          ? currentUser.profileImage 
          : data.user.profileImage} className='userImg' alt="" />
        <span>time</span>

      </div>
      <div className="messageContent">
        <p className='messageContentParagraph' >{message.text}</p>
        {message.img && <img src={message.img} className='user-message-img' alt="" />}
      </div>
    </div>
  )
}

export default Message