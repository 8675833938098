import React, { useEffect, useState, useContext } from 'react';
import { db } from './firebase.js';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { AuthContext } from './context/AuthContext.js';

function Friends() {
  const { currentUser } = useContext(AuthContext);
  const [err, setErr] = useState(null);
  const [friends, setFriends] = useState([]);

  useEffect(() => {
    const fetchFriendsData = async () => {
      try {
        const friendsRef = collection(db, 'users');
        const friendsQuery = query(friendsRef, where(`friends.${currentUser.uid}`, '==', true));
        const friendsSnapshot = await getDocs(friendsQuery);
        const friendProfiles = friendsSnapshot.docs.map((friendDoc) => {
          return { id: friendDoc.id, ...friendDoc.data() };
        });
        setFriends(friendProfiles);
      } catch (error) {
        console.error('Error fetching friends data:', error);
        setErr(error.message);
      }
    };

    if (currentUser) {
      fetchFriendsData();
    }
  }, [currentUser]);

  return (
    <div className='friends-list-container'>
      <h3>Friends</h3>
      <div className='friends-scroll-container'>
        {friends.length > 0 ? (
          <div className='friends-scroll-list'>
            {friends.map((friend) => (
              <div key={friend.id} className='friend-item'>
                <img src={friend.profileImage} alt={friend.displayName} className='friend-profile-image' />
                <div className='friend-details'>
                  <p>{friend.firstName} {friend.lastName}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No friends available.</p>
        )}
      </div>
    </div>
  );
}

export default Friends;