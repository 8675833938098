import React, { useContext } from 'react'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import  { AuthContext } from '../context/AuthContext';
import './../App.css';

const Navbar = () => {

  const {currentUser} = useContext(AuthContext);

  return (
    <div className='navbar'>
      <span className='messengerlogo'>Messages</span>
      <div className='userinfo'>
      <Link>
        <FontAwesomeIcon icon={faUserPlus} className='addFriend'/>
        </Link>
      </div>
    </div>
  )
}

export default Navbar