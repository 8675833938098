// Author: Alejandro
// This file creates the navbar connections on the website
import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './login';
import Signup from './Signup';
import Profile from './profile';
import Navigation from './navigation';
import MessagesPage from './MessagesPage';
import ContactForm from './contact';
import PasswordRecoveryEmail from './passwordRecoveryEmail';
import Events from './Events';
import Calendar from './Calendar';
import About from './About';
import { AuthContext } from './context/AuthContext';

function App() {

  // Grabs currentUser from the AuthContext
  const { currentUser } = useContext(AuthContext);
  

  // Higher-order component to protect routes that require authentication
    const ProtectedRoute = ({ element: Element, ...rest }) => {
    // Checks for authenticated user, and if not, redirects to home page
    if (!currentUser) {
      return <Navigate to="/" />;
    }
    
    return (
      <>
        <Navigation />
        <Element {...rest} />
      </>
    );
  };

  // Defining different routes for the app, first group are Protected, second group are Public
  return (
    <Router>
          <Routes>
           <Route path="/Profile/:userId" element={<ProtectedRoute element={Profile} />}/>
           <Route path="/Calendar" element={<ProtectedRoute element={Calendar} />} />
           <Route path="/Contact" element={<ProtectedRoute element={ContactForm} />} />
           <Route path="/Messaging" element={<ProtectedRoute element={MessagesPage} />} />
           <Route path="/Events" element={<ProtectedRoute element={Events} />} />
           <Route path="/About" element={<ProtectedRoute element={About} />} />

           <Route path="/" element={<Login />} />
           <Route path="/login" element={<Login />} />
           <Route path="/signup" element={<Signup />} />
           <Route path="/passwordRecoveryEmail" element={<PasswordRecoveryEmail />} />
      </Routes>
    </Router>
  );
}

export default App;




