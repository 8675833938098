import React from "react";
import SideBar from "./Message_Components/SideBar";
import Chat from "./Message_Components/Chat";
import './App.css';


const MessagingPage = () => {
    return (
        <div className='MessagingPage'>
            <div className="container">
            
            <SideBar/>

            <Chat/>
           

            </div>
        </div>  
    )
}

export default MessagingPage