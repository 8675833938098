//author: Hunter Oxley 
//page for users to signup with their WVU mix email

import { useNavigate } from 'react-router-dom';
import './App.css';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getFirestore } from 'firebase/firestore'; 
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';

//set an email pattern to ensure WVU mix emails are used for signups
const emailPattern = /\b[A-Za-z0-9._%+-]+@mix\.wvu\.edu\b/;
//set password pattern to ensure password contains one special char, one num, one cap, minimum 8 char
const passwordPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; 


/**
 *  component for the signup page.
 */
function Signup() {
    //Set data types to be collected
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        profileImage:''

    });

    //State variables for passwoord visibilty and password confirmation
    const [showPassword, setShowPassword] = useState(false); //show password const
    const [confirmPw, setConfirmPw] = useState("");//confirm pw 
    const [showConfPw, setConfPw] = useState(false);

  /**
   * Toggles the visibility of the password input field.
   */
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };


  /**
   * Toggles the visibility of the confirm password the input field.
   */
      const toggleConfirmPasswordVisibility = () => {
        setConfPw(!showConfPw);
      };


    //Init navigate to redirect user after signup
    const navigate = useNavigate(); 

  /**
   * Handle input changes in the form.
   * @param {Event} e - The input change event.
   */
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        })
    }

  /**
   * Handles submission of signup form.
   * @param {Event} e - The form submission event.
   */

    const handleSignup = async (e) => {
        e.preventDefault();

        //init firestore auth & database
        const auth = getAuth();
        const db = getFirestore(); 

        
        try {
             // Validation checks for WVU mix email and password
            if (!formData.email.match(emailPattern)) {
                 alert('Please use your WVU student email address to sign up!');
                 return;
             }

             if (!formData.password.match(passwordPattern)) {
                 alert('Password should be at least 8 characters long and contain at least one digit, one uppercase letter, and one special character among @$!%*?&.');
                 return;
             }
             if(!formData.password.match(confirmPw)){
                alert("Passwords do not match, please try again");
                return;
               }
               else{

            //Log user data
            console.log('User data:', formData);

            //Signing users up
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                formData.email,
                formData.password

            );

            //Confirm user was succesfully created
            const user = userCredential.user;
            console.log('User created:', user);

            // Add user information to the "users" collection in Firestore
            const userDocRef = doc(db, 'users', user.uid);
            await setDoc(userDocRef, {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                profileImage: 'https://firebasestorage.googleapis.com/v0/b/usocial-d7b43.appspot.com/o/Default_pfp.svg.png?alt=media&token=0046254d-fc6d-4130-bf28-503c900846f8',
                uid: userCredential.user.uid,
                friends: []

            });
            //add information to userchats collection firestore @alejandro
            await setDoc(doc(db, "userChats", user.uid), {}); 

            // Redirect to the user's profile page
            navigate(`/profile/${user.uid}`);
        }
            
        } catch (error) {
            //Handle and display any error message to the console
            console.log('idiot');
            console.error(error.code, error.message);
            
        }
    
    }

    //Structure for signup page 
    return (
        <div className="container" id = "signup-container">
            <h2>Create your USocial account!</h2>
            <form id="signup" className="signup" onSubmit={handleSignup}>
            
                 {/* Input field for the first name */}
                <input type="text" id="firstName" name="firstName" required  
                placeholder="First Name" value={formData.firstName} onChange={handleInputChange}
                />

                  {/* Input field for the last name */}
                <input type="text" id="lastName" name="lastName"required
                  placeholder="Last Name" value={formData.lastName} onChange={handleInputChange}
                />
                    {/* Input field for the Email */}
                <input type="email" id="email" name="email"  required
                    placeholder="Email" value={formData.email} onChange={handleInputChange}
                />
           

                    {/* Input field for the password*/}
                <div className="password-input"> 
                    <div >  
                        <input type={showPassword ? 'text' : 'password'} id="password" name="password" required
                    placeholder="Password" value={formData.password} onChange={handleInputChange}
                        />
       
                     {/* Eye icon button to toggle password visibility */}
                     <span
                        className="eye-icon"
                        style={{
                        position: 'absolute',
                        top: '50%',
                        right: '10px',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        }} onClick={togglePasswordVisibility}>
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                         </span>
                    </div>
                </div>
                {/** input to confirm password */}
                <div className="confirm-pw">  
            <div>
                        <input type = {showConfPw ? "text": "password"} id="confirmPassword" name = 'confirmPassword'
                    placeholder="Confirm Password" onChange={(e)=> setConfirmPw(e.target.value)} value = {confirmPw}/>
                    {/* Eye icon button to toggle password visibility */}
                    <span
                        className="eye-icon"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        }} onClick={toggleConfirmPasswordVisibility}>
                    <FontAwesomeIcon icon={showConfPw ? faEye : faEyeSlash} />
                         </span>
                         </div>
                    </div>

                {/** password requirements */}
                <div id = "pwReqs">
                   <header> Password must contain:</header>
                    <li>
                    at least 8 characters
                    </li>
                    <li>
                    at least one digit 
                    </li>
                    <li>
                    one uppercase letter 
                    </li>
                    <li>
                    one special character among @$!%*?&.
                    </li>
                    </div>
                        <br />
                        <br />

                <Link to = "/login">
                <button id = "cancel">Cancel</button>
                </Link>

                <button type="submit">Create Account</button>
            </form>
        </div>
    );
}


export default Signup;
