import React, { useContext, useState } from 'react'
import {db} from "./firebase"
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { AuthContext } from './context/AuthContext'

const AddFriends = () => {
  const { currentUser } = useContext(AuthContext); // Destructure currentUser from context
  const [searchEmail, setSearchEmail] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const[user, setUser] = useState(null)
  const[err, setErr] = useState(false)
  
  const handleSearch = async () => {


    const q = query(
      collection(db,"users"),
      where("email","==", searchEmail)
    );

    
    try{
      const querySnapshot = await getDocs(q);
    
      querySnapshot.forEach((doc) => {
        setUser(doc.data())  
      });
    } catch(err){
      setErr(true)
    }
  }



    const handleKey =(e)=>{
    e.code === "Enter" && handleSearch()
  }

  const handleAdd = async () => {
    try {
      const currentUserRef = doc(db, "users", currentUser.uid);
      const userRef = doc(db, "users", user.uid);
  
      // Check if the current user already has a friends collection
      const currentUserDoc = await getDoc(currentUserRef);
      if (!currentUserDoc.exists()) {
        // If the current user doesn't have a friends collection, create it
        await setDoc(currentUserRef, { friends: {} }, { merge: true });
      }
  
      // Check if the user being added as a friend already has a friends collection
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        // If the user being added doesn't have a friends collection, create it
        await setDoc(userRef, { friends: {} }, { merge: true });
      }
  
      // Update the friends list for the current user
      await updateDoc(currentUserRef, {
        [`friends.${user.uid}`]: true,
      });
  
      // Update the friends list for the user being added
      await updateDoc(userRef, {
        [`friends.${currentUser.uid}`]: true,
      });
  
      // Clear the user details after adding as a friend
      setUser(null);
      setSearchEmail("");
    } catch (err) {
      console.error("Error adding friend: ", err);
    }


    window.location.reload();

  };




  return (
    <div>
       <div className="searchForm-email">
        <input type="text" className='emailSearch' placeholder='Search User by Email' onKeyDown={handleKey} onChange={e=>setSearchEmail(e.target.value)} value={searchEmail}/>
      </div>
      {err && <span>user not found</span>}
      {user && (
 <div className="friendSearch">
 <img
   src={user.profileImage}
   className="userImg-search-friend"
   alt=""
   style={{ width: '50px', height: '50px', objectFit:'cover'}}
 />
 <div className="friend-search-result">
   <span>{user.firstName}</span>
 
   <button className='add-friends-confirm' onClick={handleAdd}>Add Friend</button>
 </div>
</div>

  )}
    </div>
  );
};

export default AddFriends;
