import React, { useContext } from 'react'
import Messages from './Messages';
import Input from "./Input"
import { ChatContext } from '../context/ChatContext';
const Chat = () => {
const {data} = useContext(ChatContext);
  return (
    <div className='chatWindow'>
      <div className="chatInfo">
  
       <img src={data.user.profileImage} className = "userImg" alt="" />
       <span>{data.user?.firstName}</span>
      
      </div>
        <Messages/>
        <Input/>
    </div>
  )
}
//test

export default Chat