//author: Jacob Collins
//page to send a password recovery email and change password

import React, { useState } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

/**
 * Component for password recovery with users Email
 * Allows users to enter their MIX email and sends a password recovery email.
 */

const PasswordRecoveryEmail = () => {
  
  const [email, setEmail] = useState('');

   /**
   * Sends password recovery email using EmailJS.
   */
  const sendPasswordRecovery = () => {
    const auth = getAuth();

    sendPasswordResetEmail(auth, email)

      .then(() => {
        
        window.alert('Password reset email sent successfully!');
      })
      .catch((error) => {

        console.error('Error sending password reset email:', error.message);

      });

  };

  //Structure for password recovery page 
  return (
    <>
      <html>
        <head>
          <link rel="icon" href="logo.jpg" type="image/x-icon" />
          <meta charset="UTF-8" />
          <title>Password Recovery</title>
        </head>
        <body>
          <div className="container">
            <h2>Forgot your password?</h2>
            <h3>Enter your associated USocial email:</h3>
            {/* User enters their email */}
            <label htmlFor="email"></label>
            <input
              type="text"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {/* Button to send the password recovery email */}
            <button type="button" onClick={sendPasswordRecovery}>
              Send Email Verification
            </button>
            <Link to="/" id = "Login-link">
              {/* Button to go back to login page */}
            <button>Back to Login</button>
            </Link>
            <br />
          </div>
        </body>
      </html>
    </>
  );
};

export default PasswordRecoveryEmail;
