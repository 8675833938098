/**Author: Alex Santiago
 * Purpose: Creates the Navbar used in all pages accessible to the user. 
 * The Navbar allows the users to easily and quickly move from page to page. 
 * These include the profile, messages, calendar, page about the app, contact admins page, and the logout feature.**/

import React, { useEffect, useState }from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faCalendar } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import Firebase Authentication module
import { getFirestore, doc, getDoc } from 'firebase/firestore'; // Import Firestore functions
import {useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

const Navigation = () => {
//declaring useStates and useNavigation that allows access to user account/ ability to logout or navigate through user-accessible pages. 
    const [profileImageURL, setProfileImageURL] = useState(null);
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        const db = getFirestore(); // Obtain Firestore instance

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUserId(user.uid); 
                try {
                    // Fetch the user's document from Firestore where the profile image URL is stored
                    const userDocRef = doc(db, 'users', user.uid); // Use 'doc()' method to reference the document
                    const docSnap = await getDoc(userDocRef);

                    if (docSnap.exists()) {
                        // Assuming the profile image URL is stored in a 'profileImage' field
                        const imageURL = docSnap.data().profileImage; // Replace with the correct field name
                        setProfileImageURL(imageURL); // Set profileImageURL state
                    } else {
                        console.log('No such document!');
                    }
                } catch (error) {
                    console.error('Error getting document:', error);
                }
            } else {
                setProfileImageURL(null); // If no user is logged in, set profileImageURL to null
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

//function to allow the user to log out of their account
    const Logout = async () => {
        const auth = getAuth();
        
        try {
            await signOut(auth);
            setUser(null);
            console.log('User successfully logged out');
            localStorage.removeItem('isLoggedIn'); //remove the local storage showing the user is logged in which prevents accidental logout whe the page refreshes. 
            navigate('/'); //takes the user back to the log in page
        } catch (error) {
            console.error('Error during logout:', error); 
        }
    }
    return (
        //UI for Navbar
        <nav className='masterNavbar'>
            <ul>
                <div className='LeftItems'> 
                <li>
                    {/** link to user profile */}
                    <Link to ={`/Profile/${userId}`}>
                    <FontAwesomeIcon icon={faUser} className='profileIcon'/>
                    </Link>  
                </li>
                <li>
                    {/** link to user's messages */}
                    <Link to = "/Messaging">
                    <FontAwesomeIcon icon={faEnvelope} className='messages'/>
                    </Link>
                </li>
                <li>
                    {/** link to user's calendar */}
                    <Link to = "/Calendar">
                    <FontAwesomeIcon icon={faCalendar} className='CalendarIcon'/>
                    </Link>
                </li>
                </div>

                <div className='RightItems'>
                <li>
                    {/** link to the page about USocial */}
                    <Link to = "/About"> About </Link>
                </li>
                <li>
                    {/** link to reporting page */}
                    <Link to = "/Contact"> Contact </Link>
                </li>
                <li>
                    {/** link to log the user out of their account */}
                    <Link to = "/login" onClick={Logout}> Logout </Link>
                </li>
                {/** display user's profile picture */}
                {profileImageURL && <img src={profileImageURL} alt='Profile' className='userProfilePhoto' />}
               </div>
            </ul>
        </nav>
    );
};

export default Navigation;
