//author: Hunter Oxley
//page to log users into their USocial account
//pulls  the  information from firebase and
import React, { useState } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import logo from './logo.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './App.css';

 /**
   * Login page component
   */
function Login() {
  // Variables to store user input4
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  //Router hook to handle navigation
  const navigate = useNavigate();

  //Manage password visibility  
  const [showPassword, setShowPassword] = useState(false); 

   /**
   * Function to toggle the visibility of the password in the input field on login.
   */
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Handle submitting login form
   * @param {Event} e - The form submission event.
   */
  const handleLogin = async (e) => {
    e.preventDefault();

    //Creating instance of firebase authentication
    const auth = getAuth();

    try {
      //Sign in with email and password 
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      //Get user ID after login
      const userId = userCredential.user.uid;

      //User successfully logged in and is redirected to their profile page
      console.log('User successfully logged in');
      localStorage.setItem('isLoggedIn', 'true');
      navigate(`/profile/${userId}`);

    } catch (error) {
      //Error occured when trying to login
      console.error('Error during login:', error.message);
      
    }
  };

  //Structure for login page
  return (
    <div className="grid-container">
      <div className="grid-item">
        <img src={logo} className="logo" alt="Logo" />
        <br />
        <br />
        <br />
        <br />
        <div id="intro">Connect with classmates and manage your schedule</div>
      </div>
      <div className="grid-item">
        <div id="welcomMsg">Welcome to USocial!</div>
        <form id="login" onSubmit={handleLogin}>
          <label htmlFor="username"></label>
           {/* Enter email to login */}
          <input
            type="text"
            id="username"
            name="username"
            required
            placeholder="Username"
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
           {/* Enter password to login */}
          <div className="password-input">
          <label htmlFor="password"></label>
          <input
            type={showPassword ? 'text' : 'password'} 
            id="password" 
            name="password" 
            required
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* Eye icon button to toggle password visibility */}
          <span
             className="eye-icon"
             style={{
             position: 'absolute',
             top: '50%',
             right: '10px',
             transform: 'translateY(-50%)',
             cursor: 'pointer',
             }} onClick={togglePasswordVisibility}>
         <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </span>
              </div>



          <br />
          <button id="login-link" type="submit">
            Sign In
          </button>
        </form>
        <div className="button-container">
          {/* Signup Button */}
          <Link to="/signup" id="signup-link">
            <button>Create Account</button>
          </Link>

          {/* Forgot Password button */}
          <Link to="/PasswordRecoveryEmail" id = "passwordRecoveryEmail-link">
            <button>Forgot Password?</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
