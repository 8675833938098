import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {initializeApp} from 'firebase/app'
import{getFirestore, collection, onSnapshot} from 'firebase/firestore'
import {getAuth, createUserWithEmailAndPassword, signOut} from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import {getStorage} from 'firebase/storage'
import { AuthContextProvider } from './context/AuthContext';
import { ChatContextProvider } from "./context/ChatContext";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthContextProvider>
     <ChatContextProvider>
  <React.StrictMode>
    <App />
  </React.StrictMode>
   </ChatContextProvider>
  </AuthContextProvider>
);

const firebaseConfig = {
  apiKey: "AIzaSyCCU8DOYUPjjGm52jLv3fJCV9wzKdp3at0",
  authDomain: "usocial-d7b43.firebaseapp.com",
  projectId: "usocial-d7b43",
  storageBucket: "usocial-d7b43.appspot.com",
  messagingSenderId: "80930718185",
  appId: "1:80930718185:web:87d8848104e926c43acb78"
};

//init firebase app
const firebaseApp = initializeApp(firebaseConfig)

//init services
const db = getFirestore()
const auth = getAuth()
const storage = getStorage(firebaseApp);

//collection ref
const colRef = collection(db, 'users')

//get real time collection data
onSnapshot(colRef, (snapshot) => {
  let users = []
snapshot.docs.forEach((doc) =>{
users.push({...doc.data(), id: doc.id})
})
console.log(users)
})

const database = getDatabase(firebaseApp);

export {database, storage};






