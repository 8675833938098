
import { initializeApp } from 'firebase/app';
import { getAuth, browserLocalPersistence, setPersistence } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from "firebase/firestore";
import 'firebase/auth';


//web app firebase config
const firebaseConfig = {
       apiKey: "AIzaSyCCU8DOYUPjjGm52jLv3fJCV9wzKdp3at0",
        authDomain: "usocial-d7b43.firebaseapp.com",
        projectId: "usocial-d7b43",
        storageBucket: "usocial-d7b43.appspot.com",
        messagingSenderId: "80930718185",
        appId: "1:80930718185:web:87d8848104e926c43acb78"
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage();
const db = getFirestore()

export { db, auth, app, storage};



