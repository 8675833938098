import React, { useContext, useState, } from 'react'
import {db} from "../firebase"
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";

import { AuthContext } from '../context/AuthContext'

const Search = () => {

  const[username, setUsername] = useState("")
  const[user, setUser] = useState("")
  const[err, setErr] = useState(false)

  const { currentUser } = useContext(AuthContext);

  const handleSearch = async() => {
    const q = query(
      collection(db,"users"),
      where("firstName","==", username)
    );


  try{
    const querySnapshot = await getDocs(q);
  
    querySnapshot.forEach((doc) => {
      setUser(doc.data())  
    });
  } catch(err){
    setErr(true)
  }

  }


  const handleKey =(e)=>{
    e.code === "Enter" && handleSearch()
  }

  const handleSelect = async () => {
  //check whether group exists or not(chats in firestore)
  const combinedId = 
    currentUser.uid > user.uid 
     ? currentUser.uid + user.uid 
     : user.uid + currentUser.uid;
  try{
  const res = await getDoc(doc(db, "chats", combinedId));

  if (!res.exists()) {
    //create a chat in chats collection
    await setDoc(doc(db, "chats", combinedId), { messages: [] });



  
console.log(user)
    //create user chats
    await updateDoc(doc(db, "userChats", currentUser.uid), {
      [combinedId + ".userInfo"]: {
        uid: user.uid,
        firstName: user.firstName,
        profileImage: user.profileImage
      },
      [combinedId + ".date"]: serverTimestamp(),
    });
  


    await updateDoc(doc(db, "userChats", user.uid), {
      [combinedId + ".userInfo"]: {
        uid: currentUser.uid,
        firstName: currentUser.firstName,
        profileImage: currentUser.profileImage
      },
      [combinedId + ".date"]: serverTimestamp(),
      
    });

 
    }



     }catch(err){}  
  
      setUser(null);
      setUsername("")
   }; 

  return (
    <div className='search'>
      <div className="searchForm">
        <input type="text" className='messageSearch' placeholder='Search' onKeyDown={handleKey} onChange={e=>setUsername(e.target.value)} value={username}/>
      </div>
      {err && <span>user not found</span>}
      {user && (<div className="userChat" onClick={handleSelect}>
        <img src={user.profileImage} className = "userImg" alt="" />
        <div className="userChatInfo">
          <span>{user.firstName}</span>

        </div>
      </div>)}
    </div>
  )
}

export default Search