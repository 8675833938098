import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { ref, getStorage, uploadString, getDownloadURL } from 'firebase/storage';

const ProfilePhotoUpload = ({ onUploadSuccess }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleFileChange = (event) => {
    setSelectedImage(event.target.files[0]);
    setUploadSuccess(false); // Reset upload success status when a new file is selected
  };

  const handleUpload = async () => {
    if (!selectedImage) {
      alert('Please select an image');
      return;
    }

    const file = selectedImage;

    // Convert the file to a base64-encoded string
    const reader = new FileReader();
    reader.onload = async (e) => {
      const base64String = e.target.result;

      // Upload the base64-encoded image to Firebase Storage
      const storage = getStorage();
      const storageRef = ref(storage, `user-profile-images/${getAuth().currentUser.uid}`);

      try {
        const snapshot = await uploadString(storageRef, base64String, 'data_url');

        // Get the download URL of the uploaded image
        const downloadURL = await getDownloadURL(snapshot.ref);

        // Update user profile in Firestore with the image URL
        const db = getFirestore();
        const userDocRef = doc(db, 'users', getAuth().currentUser.uid);

        await updateDoc(userDocRef, {
          profileImage: downloadURL // Assuming your user document has a field 'profileImage' to store the URL
        });

        console.log('Image uploaded and profile updated successfully.');
        setUploadSuccess(true); // Set upload success status to true
      
        window.location.reload();
      } catch (error) {
        console.error('Error uploading image:', error);
        // Handle error scenarios
      }
    };

    reader.readAsDataURL(file);
  };

  const closeModal = () => {
    setUploadSuccess(false); // Close the modal
  };

  return (
    <div className='profile-photo-upload'>
      <div className='photo-upload-container'>
        <span className='upload-text'>Upload a Profile Photo</span>

        <input type="file" onChange={handleFileChange} accept="image/*" />
        <button className='upload-button' onClick={handleUpload}>Upload</button>

        {uploadSuccess && (
          <div className='modal-overlay-2'>
            <div className='modal-2'>
              <p>Profile picture uploaded successfully!</p>
              <button className="close-button" onClick={closeModal}>Close</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};


export default ProfilePhotoUpload;

