//author: Emily Krugman 
//page for reporting a problem to admin email
//pulls information from firebase and utilizes emailjs services to send the email

import React, { useRef, useState } from 'react';
import {getAuth} from "firebase/auth";
import {Link} from 'react-router-dom';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [message,setMessage] = useState("");

  //utilized code template from firebase to create code to access user data
//initialize variables and obtains current user visiting the page (must be logged in for access)
  const auth = getAuth();
  const user = auth.currentUser;
  var email = "";
 
  if(user !== null){
    //saves user email from database for verified contact info
    user.providerData.forEach((profile) => {
 email = profile.email;
    });
  }
 //end of firebase template

   //used code template from emailjs website, edited with our account information and alerts 
   //allows the form filled out by the user to be sent to the admin gmail account
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dw2fmgl', 'template_ohmufmt', form.current, "E8GLooyL-FfIkw07I")
      .then((result) => {
        console.log('SUCCESS!');
        alert("Thank you for your submission. If additional information is needed we will contact you");
        //sets the text back to null so the user does not duplicate reports
        setMessage("");
    }, (error) => {
          console.log(error.text);
      });

  };
  //end of emailjs function

return (
  <>
  {/**contact form UI*/}
    <form ref={form}>
<div class = "container">
<h1 id = "reportProbHeader">Report a Problem</h1>

{/** hidden input for account info */}
<div>
  <input type = "hidden" name = "email" defaultValue={email}> 
</input>
  </div>
<br />

{/** dropdown menu for problem type */}
<div class = "dropdownMenu">
<label id = "probTypeText">Select a Category: </label>
<select name = "probType" id = "probType" required >
<option value="harassment">Harassment/ Report a User</option>
<option value="technical">Technical Problems</option>
<option value="other">Other</option>
</select>
</div>
<br />
<br />

{/*user problem report*/}
<div>
<textarea name = "message"id = "message" placeholder="Enter your message here" onChange = {(e)=> setMessage(e.target.value)} value = {message}  required/>
</div>

{/*cancel returns to profile*/}
<div>
<Link to = "/profile/:userId">
<button id = "cancel">Cancel</button>
</Link>

{/** submit report */}
<button id="submitProblem" onClick={sendEmail}>Submit</button>
</div>

</div>
</form>
  </>
);
};

export default ContactForm;
