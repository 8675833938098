import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import './App.css';
const Calendar = () => {

  const navigate = useNavigate();
 

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [eventArray, setEventArray] = useState({});

  const generateCalendar = () => {
    const firstOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    const lastOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    const monthYearElement = `${months[currentMonth]} ${currentYear}`;
    const calendarGrid = [];

    let date = 1;

    for (let i = 1; i <= 6; i++) {
      const week = [];

      for (let j = 0; j <= 6; j++) {
        if (j < firstOfMonth && i === 1) {
          week.push(<td key={`blank-${j}`} />);
        } else if (date > lastOfMonth) {
          break;
        } else {
          const dateClicked = date;
          const arrayofEvents = eventArray[dateClicked] || [];

          const day = (
            <td key={date} onClick={() => addEventButton(dateClicked)}>
              {date}
              {arrayofEvents.length > 0 && <span><br />🔵</span>}
            </td>
          );

          week.push(day);
          date++;
        }
      }

      calendarGrid.push(<tr key={`week-${i}`}>{week}</tr>);
    }
    const goToEvents = () => {
      navigate('/events');
  }

    return (
      <div className="Calendar">
        <div className="calendar-interactions">
        <div className="labels">
          <h2 id="Month-Year" align="center">{monthYearElement}</h2>
          <p cellSpacing="500" align="center">
            <button className ="previousMonth" onClick={previousButton} span="left" align="center">{'<'}</button>
            <button className ="nextMonth" onClick={nextButton} span="right" align="center">{'>'}</button>
          </p>
          <button className="events" onClick={goToEvents}>Events</button>
         
        </div>
        </div>
        <div className="calendar-days-container">
        <table id="calendarContainer" align="center" cellSpacing="0" cellPadding="21">
          <thead>
            <tr>
              {days.map((day, index) => (
                <th key={index} style={{ width: "100px" }}>{day}</th>
              ))}
            </tr>
          </thead>
          <tbody id="calendar-grid">
            {calendarGrid}
          </tbody>
        </table>
        </div>
      </div>
    );
  };

  const previousButton = () => {
    if (currentMonth === 0) {
      setCurrentYear(currentYear - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const nextButton = () => {
    if (currentMonth === 11) {
      setCurrentYear(currentYear + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const addEventButton = (dateClicked) => {
    const arrayofEvents = eventArray[dateClicked] || [];

    if (arrayofEvents.length > 0) {
      let popUp = `Event on ${months[currentMonth]} ${dateClicked}, ${currentYear} - `;
      arrayofEvents.forEach(event => {
        popUp += `${event.name} at ${event.time} \n`;
      });
      alert(popUp);
    } else {
      const nameofEvent = prompt("Enter the name for the event");
      const timeofEvent = prompt("Enter the time the event occurs");

      if (nameofEvent && timeofEvent) {
        const data = {
          name: nameofEvent,
          time: timeofEvent,
          date: dateClicked,
          month: currentMonth,
          year: currentYear
        };

        if (!eventArray[dateClicked]) {
          setEventArray({ ...eventArray, [dateClicked]: [data] });
        } else {
          setEventArray({
            ...eventArray,
            [dateClicked]: [...eventArray[dateClicked], data]
          });
        }
      }
    }
  };

  return (
    <div>
      <h1 align="left">USocial</h1>
      {generateCalendar()}
    </div>
  );
};

export default Calendar;
