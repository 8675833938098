//@author Alejandro/Jacob
//this file renders the profile and updates with user data

//imports
import React, { useEffect, useState, useRef, useContext } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getFirestore, updateDoc, getDoc } from 'firebase/firestore';
import { Navigate, useNavigate } from 'react-router-dom';
import ProfilePhotoUpload from './ProfilePhotoUpload';
import { useParams } from 'react-router-dom';
import AddFriends from './AddFriends';
import { AuthContext } from './context/AuthContext'
import Friends from './Friends';



function Profile() {
   

    const { userId } = useParams();

    //setting the user
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);


     //these consts allow modal visibility
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [friendsModalOpen, setFriendsModalOpen] = useState(false);

    //allow for navigate
    const navigate = useNavigate();

  //grab context
    const {currentUser} = useContext(AuthContext)

    useEffect(() => {
          //authentification
        const auth = getAuth();
        
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const db = getFirestore();
                const userDocRef = doc(db, 'users', user.uid);
              
                try {
                    // Get user document in Firestore
                    const userDoc = await getDoc(userDocRef);

                    // If the user exists, set the user with its information
                    if (userDoc.exists()) {
                        const userData = userDoc.data();

                        setUser({
                            uid: user.uid,
                            email: user.email,
                            firstName: userDoc.data().firstName,
                            profileImage: userDoc.data().profileImage
                        });

  
                    } else {
                        // User does not exist
                        setUser(null);
                    }
                } catch (error) {
                    // Handle any errors when fetching document and display a message in the console
                    console.error('Error getting user document:', error);
                    setUser(null);
                }

                setLoading(false);
            } else {
                setUser(null);
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);



 
//nav to contact form
    const goToContactForm = () => {
        navigate('/contact');
    }

//nav to events page
    const goToEvents = () => {
        navigate('/events');
    }


    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return <Navigate to="/login" />;
    }

    // Set firstName to display on the profile welcome message
    const displayName = user.firstName || 'User';

    const openUploadModal = () => {
        setUploadModalOpen(true); // Opens the upload photo modal
    };

    const closeUploadModal = () => {
        setUploadModalOpen(false); // Closes the upload photo modal
    };

    const openFriendsModal = () => {
        setFriendsModalOpen(true); // Opens the friends modal
    };

    const closeFriendsModal = () => {
        setFriendsModalOpen(false); // Closes the friends modal
    };


    return (
       
        <div className="container">
            <div className='profile-container'>
            <div className='buttons-profile'>
            {/*buttons*/}
            <button className="events-button" onClick={goToEvents}>Events</button>      
            <button className="upload-Profile-Button" onClick={openUploadModal}> Upload Profile Picture </button>
            <button className="Add-Friends-Profile-Button" onClick={openFriendsModal}> Add Friends </button>
            {/* Render upload photo modal */}
            {uploadModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={closeUploadModal}>Close</button>
                        <ProfilePhotoUpload />
                    </div>
                </div>
            )}

            {/* Render friends modal */}
            {friendsModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={closeFriendsModal}>Close</button>
                        <AddFriends/>
                    </div>
                </div>
            )}
            <button className="contact-button" onClick={goToContactForm}>Report a problem</button>
            </div>
            
             {/*basic profile info display*/}
            <div className='headers-section'>
            <h2>Welcome, {displayName}!</h2>
            <img className = "main-profile-img" src={user.profileImage} alt="where" />
            </div>
            
            {/*friendslist display*/}
            </div>
            <div className='friends-list-display'>
                <Friends/>
            </div>
            </div>
      
        
    );
}

export default Profile;