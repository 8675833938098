import React, {useState, useEffect } from 'react';
import { addDoc, collection, getFirestore, getDocs, } from 'firebase/firestore';
import './App.css';
import './Events.css';

//Events Page Component

function Events( ){
    //Manage Events state and loading state
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    //imports data from firebase firestore database
    const db = getFirestore();
    //Variables to store form data
    const [formData, setFormData] = useState({
        name: '',
        date: '',
        time: '',
        location: '',
        description: ''
      });
    //retrieves event data from the database
    useEffect(() => {
        const getEvents = async () => {
            try {

            const eventsCollection = collection(db, 'events'); 
            const eventsSnapshot = await getDocs(eventsCollection); 

            const eventsData = eventsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));


            setEvents(eventsData);
            setLoading(false);
            } catch (error) {
                console.error('Error getting events:', error);
                setLoading(false);
            }
        };
        getEvents();
    }, []);


    if (loading) {
        return <div>Loading...</div>;
    }

    //Updates variables whenever calendar is updated
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
    //activiates when submit button is pressed
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            //creates a variable holding all event data
          const eventsCollection = collection(db, 'events');
          //adds form data to the variable and updates the collection
          await addDoc(eventsCollection, formData);
          console.log('Event added successfully to the database:', formData);
          //waits 0.2 seconds to refresh the page and update the list
          setTimeout(() => {
            window.location.reload();
          }, 200);
        } catch (error) {
          console.error('Error adding event:', error);
        }
    };
    
    //structure for the events page
    return (
        <div className="grid-container">
        <h1 className="event-header">Events Near You</h1>
        {/* The form */}
        <div className="add-event-container">
      <h1>Add Event</h1>
      <form onSubmit={handleSubmit}>
        {/* This input handles the event name */}
        <input
          type="text"
          placeholder="Event Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          placeholder="Date (e.g., MM/DD/YYYY)"
          name="date"
          value={formData.date}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          placeholder="Time"
          name="time"
          value={formData.time}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          placeholder="Location"
          name="location"
          value={formData.location}
          onChange={handleInputChange}
          required
        />
        <textarea
          placeholder="Description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          required
        ></textarea>
        <button type="submit">Add Event</button>
      </form>
    </div>
    {/* Creates an events page based on data stored in the database
        Dynamic, adds more divs the more events there are */}
        <div className="event-list">
            {events.map((event, index) => (
            <div className="event" key={event.id}>
            <h2>{event.name}</h2>
            <p><b>Date:</b> {event.date}</p>
            <p><b>When:</b> {event.time}</p>
            <p><b>Where:</b> {event.location}</p>
            <p><b>Description:</b> {event.description}</p>
        </div>
        ))}
        </div>
        </div>
    );
}

export default Events;
